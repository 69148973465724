import React from 'react';
import Loadable from 'react-loadable'

function Loading() {
  return <div>Loading...</div>;
}

const Dashboard = Loadable({
  loader: () => import('./views/Dashboard'),
  loading: Loading,
});
const ReportUserAndProduct = Loadable({
  loader: () => import('./views/ReportUserAndProduct/ReportUserAndProduct'),
  loading: Loading,
});
const Profile = Loadable({
  loader: () => import('./views/User/Profile'),
  loading: Loading,
});
const PageEditor = Loadable({
  loader: () => import('./views/PageManager/PageEditor'),
  loading: Loading,
});
const FormViewer = Loadable({
  loader: () => import('./views/Form/FormViewer'),
  loading: Loading,
});
const ListViewer = Loadable({
  loader: () => import('./views/List/ListViewer'),
  loading: Loading,
});
const VoucherApprove = Loadable({
  loader: () => import('./views/VoucherApprove'),
  loading: Loading,
});
const Checkout = Loadable({
  loader: () => import('./views/Checkout'),
  loading: Loading,
});
const PrintOrder = Loadable({
  loader: () => import('./views/PrintOrder'),
  loading: Loading,
});
const ReportVoucherSource = Loadable({
  loader: () => import('./views/Report/ReportVoucherSource'),
  loading: Loading,
});
const ReportVoucherCategoryProvince = Loadable({
  loader: () => import('./views/Report/ReportVoucherCategoryProvince'),
  loading: Loading,
});
const CCSearchPhone = Loadable({
  loader: () => import('./views/CC/SearchPhone'),
  loading: Loading,
});
const CCSearchVoucher = Loadable({
  loader: () => import('./views/CC/SearchVoucher'),
  loading: Loading,
});
const CreateProduct = Loadable({
  loader: () => import('./views/Product/CreateProduct'),
  loading: Loading,
});
const EarnPointFileReport = Loadable({
  loader: () => import('./views/EarnPointByFile/EarnPointFileReport'),
  loading: Loading,
});
const ApprovedOrdersReport = Loadable({
  loader: () => import('./views/ApprovedOrdersReport'),
  loading: Loading,
});
const AppBCTReport = Loadable({
  loader: () => import('./views/Report/AppBCTReport'),
  loading: Loading,
});
const routes = [
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/pageEditor', name: 'Quản lý trang', component: PageEditor },
  { path: '/form', name: 'Trang', component: FormViewer },
  { path: '/list', name: 'Trang', component: ListViewer },
  { path: '/profile', name: 'Trang cá nhân', component: Profile },
  { path: '/voucherApprove', name: 'Duyệt điều kiện voucher', component: VoucherApprove },
  { path: '/checkout', name: 'Duyệt điều kiện voucher', component: Checkout },
  { path: '/reportvouchersource', name: 'Báo cáo voucher từ nguồn', component: ReportVoucherSource },
  { path: '/reportvouchercategoryprovince', name: 'Báo cáo voucher từ nguồn', component: ReportVoucherCategoryProvince },
  { path: '/searchphone', name: 'Tìm kiếm số điện thoại', component: CCSearchPhone },
  { path: '/searchvoucher', name: 'Tìm kiếm voucher', component: CCSearchVoucher },
  { path: '/createProduct', name: 'Tạo sửa sản phẩm', component: CreateProduct },
  { path: '/printOrder', name: 'In đơn hàng', component: PrintOrder },
  { path: '/reportUserAndProduct', name: 'Thống kê', component: ReportUserAndProduct },
  { path: '/earnPointFileReport', name: "Thống kê file tích điểm", component: EarnPointFileReport },
  { path: '/ApprovedOrdersReport', name: "Thống kê file tích điểm", component: ApprovedOrdersReport },
  { path: '/appBCTReport', name: "Thống kê app BCT", component: AppBCTReport }

];

export default routes;
