import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, InputGroup, InputGroupAddon } from "reactstrap";
import helper from "../../services/helper";
import Base from "../../schema/Base";
import FormSchema from "../../schema/index";

const schemaDetail = [
  // { "name": "ID", "field": "id", "type": "string", "widget": "Text", "required": true, "hideExpression": "id" },
  {
    name: "Trạng thái",
    field: "isActive",
    type: "boolean",
    widget: "Checkbox",
    default: true,
  },
  {
    name: "Tên",
    field: "name",
    type: "string",
    widget: "Text",
    required: true,
  },
  {
    name: "Giá",
    field: "originPrice",
    "type": "number",
    "widget": "NumberMask",
    required: true,
  },
  {
    "name": "Phương thức thanh toán",
    "field": "payment",
    "type": "string",
    "widget": "Enum",
    "items": [
      {
        "key": "cash",
        "value": "cash"
      },
    ],
    "required": true,
    "default": "free"
  },
  {
    "name": "Tiền mặt",
    "field": "paymentCash",
    "type": "number",
    "widget": "Text",
    "default": "0"
  },
  {
    "field": "paymentPoint",
    "name": "Điểm",
    "type": "number",
    "widget": "Text",
    "default": "0"
  },
  {
    "name": "Ảnh",
    "field": "images",
    "type": "string",
    "widget": "ArrayImage",
    "required": false,
    "imageWidth": "200",
    "imageHeight": "200"
  },
  {
    "name" : "Cân nặng sản phẩm",
    "field" : "weight",
    "type" : "number",
    "widget": "Text",
    "required": true
  },
  { name: "Mô tả", field: "description", type: "string", widget: "TextArea", required: false },
  { name: "Id sản phẩm bên dms", field: "dmsYeah1ProductId", type: "string", widget: "Text", required: false },
  {
    name: "Giá mua chung",
    field: "priceBuyTogether",
    "type": "number",
    "widget": "NumberMask",
  },  
];

const ProductDetail = ({ modalVisibile, toggle, onChange, product }) => {
  const formRef = React.createRef();
  const [modal, setModal] = useState(modalVisibile);
  const [dataDetail, setDataDetail] = useState(product);

  console.log("@@vui tunh",product)
  useEffect(() => {
    setDataDetail(product);
  }, [product]);

  useEffect(() => {
    setModal(modalVisibile);
  }, [modalVisibile]);

  const onSubmit = () => {
    confirm();
  };

  const confirm = () => {
    if (formRef) {
      let error = formRef.current.checkError();
      if (error >= 0)
        return helper.alert(
          `Dữ liệu chưa đúng, kiểm tra lại thông tin ${this.state.schemaDetail[error].name}`
        );
    }

    console.log("confirm -> dataDetail ", dataDetail);
    if (onChange) {
      onChange(dataDetail);
    }
    toggle();
  };

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      fade={false}
      size={"lg"}
    >
      <ModalHeader>Thêm chi tiết</ModalHeader>
      <ModalBody>
        <FormSchema
          ref={formRef}
          schema={schemaDetail}
          data={dataDetail && dataDetail.product || {}}
          onChange={(detail) => {
            setDataDetail({
              ...dataDetail,
              product: detail
            });
          }}
          onSubmit={onSubmit}
        >
          <Button
            onClick={() => {
              confirm();
            }}
          >
            Thực hiện
          </Button>
        </FormSchema>
      </ModalBody>
      <ModalFooter></ModalFooter>
    </Modal>
  );
};

export default ProductDetail;
